<template>
  <b-nav-item-dropdown
    class="dropdown-notification"
    menu-class="dropdown-menu-media"
    style="list-style-type:none"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="un_read_notification.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->

    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h3 class="notification-title m-1 mr-auto font-weight-bolder dark">
          Notifications
        </h3>
      </div>
    </li>

    <!-- Notifications -->

<!--    meka un-comment krnn-->

   <vue-perfect-scrollbar
     :show="formLoading"
     v-if="un_read_notification.length > 0"
     class="scrollable-container media-list scroll-area"
     style="height: 357px;border: 1px solid #E5E5E5;border-radius: 4px;"
     tagname="li"
   >
     <ul class="list-group">
       <li>
         <b-media
           v-for="notification in un_read_notification"
           :key="notification.id"
         >
           <div class="notificationBar">
             <b-row
             class="mt-n1 ml-0 mr-0"
             @click="ReadNotification(notification.id)">
               <!-- Left Side  -->

               <b-col class="pb-0 pt-1" cols="10">
                 <div class="row">
                   <div class="d-flex align-items-start">
                     <b-avatar class="ml-1" size="32" variant="light-info">
                       <feather-icon :icon="notification.icon" size="18" />
                     </b-avatar>
                     <div class="ml-1">
                        <p class="mb-0 font-weight-bolder" v-bind:class="text_color1">
                         {{ notification.data.message }}
                       </p>
                       <p
                         class="mb-0 font-weight"
                         v-if="notification.created_at"
                       >
                         {{
                           momentFormat(notification.created_at, 'MMM  Do,  h:mm a')
                         }}
                       </p>
                     </div>
                   </div>
                 </div>
               </b-col>

               <!-- Right side Trash button  -->

               <b-col class="pt-0  pt-xl-2 pb-xl-0 ml-n1 " cols="2">
                 <b-row>
                   <div
                     class="d-flex justify-content-start  mt-2 mt-md-0 pl-0 pl-md-0 ml-auto"
                   >
                     <b-button
                       v-b-tooltip.hover.top="'Read'"
                       class="btn-icon  text-primary"
                       size="23"
                       variant="outline-white"
                     >
                       <feather-icon
                         class="cart-item-remove cursor-pointer"
                         icon="CheckCircleIcon"
                         size="18"
                       />
                     </b-button>
                   </div>
                 </b-row>
               </b-col>
             </b-row>
           </div>
         </b-media>
       </li>
     </ul>
   </vue-perfect-scrollbar>



    <!-- No Notifications -->

    <div v-else class="text-center">
      <b-card class="d-flex align-items-center">
        <div class="m-5">
          <div class="mb-2">
             <b-img
              :src="require('@/assets/images/pages/bell.png')"
              alt="browser img"
              class="mr-1"
              size="70"
              />
          </div>
          <h5>No Notifications</h5>
        </div>
      </b-card>
    </div>

    <!-- Cart Footer -->

    <!--    mekat un comment krnn-->
   <li class="dropdown-menu-footer m-1" >
    <div v-if="un_read_notification.length > 0">
     <b-button
       v-ripple.400="'rgba(255, 255, 255, 0.15)'"
       block
       variant="primary"
       @click="readAll()">
       Read all notifications
     </b-button>
    </div>
    <div v-else>
     <b-button
       :disabled = true
       v-ripple.400="'rgba(255, 255, 255, 0.15)'"
       block
       variant="primary"
       >Read all notifications
     </b-button>
    </div>
   </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BRow,
  BImg,
  BCol,
  BCard,
  VBTooltip,
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Ripple from 'vue-ripple-directive';
import notification from '@/apis/modules/notifications';
import practice from '@/apis/modules/practice';
import MomentMixin from '@/mixins/MomentMixin';
import {loginAppUrl } from '@/constants/config';

export default {
  components: {
    BRow,
    BCol,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BCard,
    BImg,
    VBTooltip,

  },
  mixins: [MomentMixin],

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      formLoading:false,
      text_color1: 'textColor1',
      text_color2: 'textColor2',
      hiddenNotoificationBar:false,
      un_read_notification:[],
      userNotifications: [],
      beforeLength: 0,
    };
  },

  methods: {

    async ReadNotification(id){
       const Response = await notification.ReadNotification(id);
       this.showSuccessMessage('Read Notification Successfully')
       await this.getNotifications();
       this.un_read_notification.length--
    },

     async DeleteNotification(id){
       try {
         await notification.DeleteNotification(id);
         this.showSuccessMessage('Remove Notification Successfully')
       }catch (error) {
         this.convertAndNotifyError(error)

       }
    },

    async readAll() {
      try {

        await notification.ReadAllNotifications();
        this.showSuccessMessage('Read All Notifications Successfully')
        await this.getNotifications()
      }catch (error){
      //  this.convertAndNotifyError(error)
        this.showSuccessMessage('Read All Notifications Successfully')
        await this.getNotifications()
      }
    },


     async getNotifications() {
      try {
        this.formLoading = true
        const Response = await notification.getNotifications();
        const notificationArray  = Response.data.data;
        const unReadArray1 = notificationArray.filter((x)=> x.data.type === "pcn")
        const unReadArray2 = notificationArray.filter((x)=> x.data.type === "vacantPractice")

        const unReadArray3 = unReadArray1.concat(unReadArray2)
        console.log(unReadArray3)
        
        this.un_read_notification = unReadArray3.filter(
        (x)=>{
          if(x.data.practice){
            return x.data.practice.id === Number(sessionStorage.getItem('practice_id'))

          }else if(x.data.shift){
            return x.data.shift.practice_id === Number(sessionStorage.getItem('practice_id'))
          }

        } )

        //  const unReadArray4 = unReadArray2.filter(
        // (x)=> x.data.practice.id === Number(sessionStorage.getItem('practice_id')))

        // const result = unReadArray3.concat(unReadArray2)
        // this.un_read_notification = result




     }catch(error){

        this.convertAndNotifyError(error)
     }
    }
  },

  mounted() {
    window.setInterval(() => {
      this.getNotifications()
    }, 120000)
    this.getNotifications()
  },
};
</script>

<style>
element.style {
  list-style: none !important;
}

.notificationBar{
  cursor: pointer;
  color: brown
}

.textColor1{
  color: brown;
}

.textColor2{
  color: rgb(0, 0, 0);
}
</style>
